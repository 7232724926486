import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavLink from "react-bootstrap/NavLink";

function CustomHeader() {
    return (
        <Container className="Custom-header">
            <Row className="align-items-center">
                <Col sm={8}>
                    <header className="App-header">
                        Sonia Narayanan
                    </header>
                </Col>
                <Col>
                    <Navbar bg="none" variant="light">
                        <Container>
                            <Navbar.Brand/>
                            <Nav className="mr-auto">
                                <NavLink href="#home">Home</NavLink>
                                <NavLink href="#travel">Travel</NavLink>
                                <NavLink href="#projects">Projects</NavLink>
                                <NavLink href="#contact">Contact</NavLink>
                            </Nav>
                        </Container>
                    </Navbar>
                </Col>
                </Row>
            <Row>
            </Row>
        </Container>
    );
}

export default CustomHeader;