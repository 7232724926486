import React from "react";
import {
    Sphere,
    Graticule,
    ComposableMap,
    Geographies,
    Geography,
} from "react-simple-maps";
import { PatternLines } from "@visx/pattern";

const geoUrl = "/features.json";

const highlighted = [
    "USA",
    "AUS",
    "CAN",
    "FRA",
    "ITA",
    "BEL",
    "GRC",
    "CRI",
    "MEX",
    "NZL",
    "HUN",
    "AUT",
    "IND",
    "ARE",
    "ISL",
    "JPN",
    "GBR",
    "SGP",
    "PRI",
    "DOM",
    "TCA",
    "NLD",
    "NPL",
    "CHL",
    "CHI",
    "ARG",
    "MAF",
    "VIR",
    "VGB",
    "CYM",
    "HKG",
    "SXM",
];

const MapChart = ({ setTooltipContent }) => {
    return (
        <div data-tip="">
            <ComposableMap
                data-tip=""
                onClick={(e: any) => {
                    if (e.target.width) {
                        setTooltipContent(null);
                    }
                }}
            >
                <PatternLines
                    id="lines"
                    height={1}
                    width={1}
                    stroke="#776865"
                    strokeWidth={10}
                    background="#e2dcd5"
                    orientation={['vertical', 'horizontal']}
                />
                <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
                <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
                <Geographies geography={geoUrl} stroke="#FFF" strokeWidth={0.5}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const isHighlighted = highlighted.indexOf(geo.id) !== -1;
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={isHighlighted ? "url('#lines')" : "#e2dcd5"}
                                    onMouseEnter={() => {
                                        setTooltipContent(`${geo.properties.name}`);
                                    }}
                                    onMouseLeave={() => {
                                        setTooltipContent("");
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
            </ComposableMap>
        </div>
    );
};

export default MapChart;
