import React, {useLayoutEffect, useRef, useState} from "react";
import CustomHeader from "./CustomHeader";
import MapChart from "./MapChart";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {motion, useTransform, useScroll} from "framer-motion";
import ReactTooltip from "react-tooltip";
import './App.css';

function App() {
    const { scrollY } = useScroll();
    const scaleMiddle = useTransform(scrollY, [0, 150], [0.25, 2]);
    const xRight = useTransform(scrollY, [0, 0], ["0vw", "25vw"]);
    const isMobile = window.innerWidth < 768;
    const [content, setContent] = useState("");

    return (
        <Col>
            <Row>
                <CustomHeader/>
            </Row>
            <Row>
                <Col className="App-sub-header">26 year-old software engineer hitting tennis balls and traveling the world.</Col>
            </Row>
            <MapChart setTooltipContent={setContent}/>
            <ReactTooltip>{content}</ReactTooltip>

            /*
            <div>
                {!isMobile && <div style={{ height: "110vh", display: "flex", position: "sticky"}}>
                    <motion.div
                    className="child"
                    style={{
                        height: "100%",
                        width: "50vw",
                        scale: scaleMiddle,
                        x: xRight,
                    }}
                    >
                        <MapChart setTooltipContent={setContent} />
                        {content && <ReactTooltip>{content}</ReactTooltip>}
                    </motion.div>
                </div>}
                {isMobile && <MapChart/>}
            </div>
            */
             */
        </Col>
    );
}

export default App;
